import getCmsData from './getCmsData';

/**
 * @typedef UsefulLink
 * @property {number} id
 * @property {string} label
 * @property {string} url
 */

/**
 * Fetches the list of useful links.
 *
 * @returns {Promise<UsefulLink[]>}
 */
export async function getUsefulLinks() {
	const cmsLinks = await getCmsData({
		collection: 'useful-links',
		fields: ['id', 'label', 'url'],
		sort: 'label',
	});

	return cmsLinks.map(cmsLink => ({
		id: cmsLink.id,
		label: cmsLink.attributes.label,
		url: cmsLink.attributes.url,
	}));
}
