export const enableCookieConsent = window.catalogConfig.ENABLE_COOKIE_CONSENT?.toLocaleLowerCase() === 'true';
export const appEnv = window.catalogConfig.APP_ENVIRONMENT;
export const reportMaxTries = window.catalogConfig.REPORT_MAX_TRIES;
export const cpqHubLink = window.catalogConfig.CPQ_HUB_LINK;
export const eConfigLink = window.catalogConfig.ECONFIG_URL;
export const enableCam = window.catalogConfig.CAM_ENABLED?.toLocaleLowerCase() === 'true';
export const enforcedCam = window.catalogConfig.CAM_ENFORCED?.toLocaleLowerCase() === 'true';
export const enableExpertCare = window.catalogConfig.EXPERT_CARE_FEATURE_FLAG?.toLocaleLowerCase() === 'true';

export const REQUEST_STATUS = Object.freeze({
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
});

export const paginationPageSizes = Object.freeze([
	10,
	25,
	50,
	100,
	250,
]);
