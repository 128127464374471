import axios from 'axios';

/**
 * Get the cms data by dynamic parameters
 * @param {object} options
 * @param {string} options.collection data source
 * @param {string} options.populate component fields required
 * @param {string[]} options.fields specific fields requested
 * @param {string} options.sort sort parameters
 * @returns Promise of cms's result
 */
export default async (options) => {
	const url = `/cms/${options.collection}`;
	const params = {
		populate: options.populate,
		fields: options.fields,
		sort: options.sort,
	};

	const result = await axios.get(url, { params });

	// Axios returns response in "data", Strapi's response is { data: {}, metadata: {} }.
	// Thus, data.data.
	return result.data.data;
};
