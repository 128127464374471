import getCmsData from './getCmsData';
import { getUsefulLinks } from './getUsefulLinks';

export default {
	getSupportPageData: async () => getCmsData({
		collection: 'supports',
		populate: 'accordions',
		fields: ['title', 'description', 'isExpansible', 'isOnlyForIbmer'],
		sort: 'id',
	}),
	getUsefulLinks,
};
